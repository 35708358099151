import React, { useState, useContext } from "react"
import { useSeatsFormPage } from "./useSeatsFormPage"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage"
import { RecordModal } from "../../components/RecordModal/RecordModal"
import { permissions } from "../../Constants/permissions"
import { SimpleRecordModal } from "../../components/SimpleRecordModal/SimpleRecordModal"
import { SessionContext } from "../../Contexts/SessionContext"

export default function SeatsFormPage({ prop }: any)
{
    const { id } = prop.match.params
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const {
        loading,
        tabs,
        state,
        updateForm,
        updateErrors,
        // changes,
        submitData,
        deleteData,
        // resetChanges,
        pullData,
        newUserModal
    } = useSeatsFormPage(id)

    return (
        <React.Fragment>
            <TabbedCardFormPage
                configIdentifier={state?.seat?.extension}
                // changes={changes}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
                tabs={tabs}
                loading={loading}
                // resetChanges={resetChanges}
                onDelete={() => setDeleteModalOpen(true)}
                defineSubmitData={submitData}
                displayName={"Seat"}
                pullData={() => pullData(false)}
                permissions={[permissions.seats, permissions.admin, permissions.VHAdmin]}
            />

            <RecordModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                selectedRecords={[state.seat]}
                displayName={"Seat"}
                uniqueIdentifier={"extension"}
                action={"delete"}
                defineSubmitData={deleteData}
            />


            <RecordModal
                open={newUserModal.open}
                setOpen={newUserModal.setOpen}
                displayName={"User"}
                action={"create"}
                settingsStages={newUserModal.settingsStage}
                defineSubmitData={newUserModal.defineSubmitData}
                onSuccess={newUserModal.onSuccess}
            />
        </React.Fragment>
    )
}
