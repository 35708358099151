import React from "react"
import StagedForm from "../../components/Form/StagedForm"
import Modal from "../../components/Modal/Modal"
import { regex } from "../../Constants/regex"
import { regexError } from "../../Constants/regexError"
import { useBulkAddCallMonitoringModal } from "./useBulkAddCallMonitoringModal"

interface IProps
{
    open?: boolean
    setOpen?: (val?: boolean) => void,
    indexRef?: any
}

export function BulkAddCallMonitoringModal({ open, setOpen, indexRef }: IProps)
{

    const {
        state,
        updateErrors,
        updateForm,
        loading,
        getDestinations,
        onSubmit,
        findFieldError
    } = useBulkAddCallMonitoringModal(indexRef)

    return (
        <Modal open={open} setOpen={setOpen} maxWidth={700}>
            <h4>{"Add Call Monitoring"}</h4>
            <StagedForm
                modal
                state={state}
                loading={loading}
                onComplete={() =>
                {
                    indexRef && indexRef.current && indexRef.current.refPullData()
                    setOpen(false)
                }}
                stages={[
                    {
                        title: "Settings",
                        fields: [
                            {
                                label: "Add Moniters",
                                type: "select",
                                name: "monitors",
                                multi: true,
                                loadOptions: () => getDestinations(),
                                onChange: updateForm,
                                setError: updateErrors,
                                error: findFieldError("monitors"),
                                value: state.monitors
                            },
                            {
                                type: "text",
                                name: "password",
                                label: "PIN",
                                onChange: updateForm,
                                setError: updateErrors,
                                value: state.password,
                                error: findFieldError("password"),
                            },
                            {
                                type: "switch",
                                label: "Delete Monitoring?*",
                                name: "delete",
                                onChange: updateForm,
                                value: state.delete
                            },
                            {
                                type: "paragraph",
                                value: "* This will delete all current monitoring associated with the selected seats"
                            }
                        ],
                        onComplete: {
                            function: () =>
                            {
                                return new Promise(async (resolve) =>
                                {
                                    if (state.monitors.length > 0)
                                    {
                                        if (regex.pin.test(state.password))
                                        {
                                            resolve({ success: true })
                                        }
                                        else
                                        {
                                            updateErrors({
                                                target: {
                                                    name: "password",
                                                    value: "Please enter a 4-6 digit pin"
                                                },
                                            })
                                            resolve({ success: false })
                                        }
                                    }
                                    else if (!state.delete)
                                    {
                                        updateErrors({
                                            target: {
                                                name: "monitors",
                                                value: "Please select some monitors or delete current monitors"
                                            },
                                        })
                                        resolve({ success: false })
                                    }
                                    else
                                    {
                                        resolve({ success: true })
                                    }
                                })
                            }
                        }
                    },
                    {
                        title: "Confirm",
                        buttonText: "Confirm",
                        fields: [
                            {
                                type: "paragraph",
                                value: "Are you sure want to add these monitors to the selected seats?"
                            }
                        ],
                        onComplete: {
                            function: () =>
                            {
                                return new Promise(async (resolve) =>
                                {
                                    let feedback = await onSubmit()

                                    updateForm({
                                        target: {
                                            name: "successes",
                                            value: feedback.successes,
                                        },
                                    })
                                    updateForm({
                                        target: {
                                            name: "failures",
                                            value: feedback.failures,
                                        },
                                    })
                                    resolve({ success: true })
                                })
                            }
                        }
                    },
                    {
                        title: "Feedback",
                        fields: [
                            state?.successes?.length > 0 && {
                                type: "feedbackAccordion",
                                title: "Monitors sucessfully added",
                                items: state.successes.map((success) => `${success.id} - ${success.message}`),
                                variant: "green",
                            },
                            state?.failures?.length > 0 && {
                                type: "feedbackAccordion",
                                title: "Monitor failures",
                                items: state.failures.map((failures) => `${failures.id} - ${failures.message}`),
                                variant: "red"
                            }
                        ]
                    }
                ]}
            />
        </Modal>
    )
}