import { useEffect, useRef, useState } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { permissions } from "../../Constants/permissions"

export function useCallGroupsFormPage(id: number)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest, postRequest } = useRequest()
    const { getDestinationPromise, getMusicOnHoldPromise } = useAPIRequests()
    const tableRef = useRef()

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    const columns: IColumn[] = [
        {
            name: "displayName",
            title: "Name",
        },
        {
            name: "days",
            title: "Days Active",

            formField: {
                type: "daySelector",
                name: "days",
            },
        },
        {
            name: "start",
            title: "Start Time",
            formField: {
                type: "time",
                name: "start",
            },
        },
        {
            name: "end",
            title: "End Time",
            formField: {
                type: "time",
                name: "end",
            },
        },
        {
            name: "penalty",
            title: "Delay",
            formField: {
                type: "number",
                name: "penalty",
                maxWidth: 80,
                min: 0,
                max: 30,
                step: 5,
            },
        },
    ]

    function deleteData()
    {
        return [{
            path: "/callgroup",
            type: "delete",
            indexPagePath: "/call-groups",
            displayName: "Call Group"
        }]
    }

    function saveCallGroup(data)
    {
        data.cidPrefix = data.cidPrefix ? data.cidPrefix : null
        data.falloverLocation = data.falloverLocation === "none" ? null : data.falloverLocation

        return [
            {
                body: data,
                path: "/callgroup/" + id,
            },
        ]
    }

    function submitData()
    {
        return saveCallGroup(state)
    }

    async function pullData()
    {
        setLoading(true)
        let response = await getRequest(`/callgroup/${id}`)
        if (response)
        {
            response.falloverLocation =
                response.falloverLocation === "" || response.falloverLocation === null
                    ? "none"
                    : response.falloverLocation
            setRecord(response)
        }
        setLoading(false)
    }

    useEffect(() =>
    {
        pullData()
        // eslint-disable-next-line
    }, [id])

    function checkIfMemberIsInGroup(
        fieldFrom
    ): Promise<{ success: boolean; message: string }>
    {
        return new Promise(async (resolve) =>
        {
            if (!fieldFrom)
            {
                resolve({ success: false, message: "Please select an option" })
            }

            let response = await getRequest(
                `/callgroup/member/all/` + id + `/-1/-1`
            )

            if (response)
            {
                if (response.records)
                {
                    const filteredRecords = response.records.filter(
                        (record) => record.name === fieldFrom
                    )

                    if (filteredRecords && filteredRecords.length > 0)
                    {
                        resolve({
                            success: false,
                            message:
                                "This member is already a part of this call group",
                        })
                    } else
                    {
                        resolve({ success: true, message: "" })
                    }
                } else
                {
                    resolve({
                        success: false,
                        message: "Failed to retrieve data, please try again",
                    })
                }
            } else
            {
                resolve({
                    success: false,
                    message: "Failed to retrieve data, please try again",
                })
            }
        })
    }

    function saveTable(data)
    {
        return {
            id: data.id,
            groupId: data.groupId,
            name: data.name,
            start: data.start,
            end: data.end,
            penalty: data.penalty,
            days: {
                mon: data.days.mon,
                tue: data.days.tue,
                wed: data.days.wed,
                thu: data.days.thu,
                fri: data.days.fri,
                sat: data.days.sat,
                sun: data.days.sun,
            },
        }
    }

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Name",
                    fields: [
                        {
                            type: "text",
                            label: "Name",
                            name: "customName",
                            placeholder: "Name",
                            maxLength: 45,
                            helptext: "Allows identification throughout the control panel."
                        },
                    ],
                },
                {
                    title: "Call Options",
                    spanTwoRows: true,
                    fields: [
                        {
                            type: "text",
                            label: "Caller ID Prefix",
                            name: "cidPrefix",
                            maxLength: 45,
                        },
                        {
                            type: "select",
                            label: "Music On Hold",
                            name: "musicOnHold",
                            loadOptions: () => getMusicOnHoldPromise(),
                            options: [{ value: "default", label: "Default" }],
                        },
                        {
                            type: "select",
                            label: "Fallover Location",
                            name: "falloverLocation",
                            loadOptions: () =>
                                getDestinationPromise({
                                    highPriorityQueue: true,
                                    sipTrunkMap: false,
                                    excludeObjectRegex: "^GROUP\\/" + state.accountNo + "\\*" + state.groupNo + "$"
                                }),
                            options: [{ value: "none", label: "None" }],
                        },
                        {
                            type: "switch",
                            label: "Answered Elsewhere",
                            name: "answerElsewhere",
                        },
                        {
                            type: "switch",
                            label: "Ignore Call Forward",
                            name: "ignoreCfwd",
                        },
                    ],
                },
                {
                    title: "Ring Options",
                    fields: [
                        {
                            type: "select",
                            label: "Ring Type",
                            name: "ringType",
                            options: [
                                { value: 1, label: "Ring All" },
                                { value: 2, label: "Round Robin" },
                            ],
                            helptext: "Ring All: Ring all seats in the group at the same time; Round Robin: Ring each seat in order"
                        },
                        {
                            type: "select",
                            label: "Ring Time",
                            name: "ringTime",
                            options: [
                                { value: 10, label: "10 seconds" },
                                { value: 20, label: "20 seconds" },
                                { value: 30, label: "30 seconds" },
                                { value: 40, label: "40 seconds" },
                                { value: 50, label: "50 seconds" },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: "Members",
            icon: "users",
            disabled: false,
            sections: [
                {
                    fullWidth: true,
                    fields: [
                        {
                            type: "table",
                            customPaginationName: "callGroupMembers",
                            fieldRef: tableRef,
                            columns: columns,
                            permissions: [permissions.callGroups, permissions.admin, permissions.VHAdmin],
                            restType: "GET",
                            onSubmit: {
                                getData: saveTable,
                                path: "/callgroup/member",
                            },
                            body: { sortBy: "displayName", direction: "ASC" },
                            urlString: `/callgroup/member/all/` + id.toString(),
                            pullData: pullData,
                            addModal: {
                                displayName: "Member",
                                allowBulkAdd: false,
                                fieldFrom: {
                                    field: "Member",
                                    label: "Member",
                                    type: "select",
                                    multi: true,
                                    loadOptions: () =>
                                        getDestinationPromise({
                                            queues: false,
                                            voicemail: false,
                                            groups: false,
                                            ivrs: false,
                                            timeProfiles: false,
                                            sipTrunks: false,
                                            dialThrough: false,
                                        }),
                                },
                                settingsStages: [
                                    {
                                        title: "Settings",
                                        fields: [
                                            {
                                                type: "daySelector",
                                                name: "days",
                                                label: "Days Active",
                                                defaultDayValues: { mon: true, tue: true, wed: true, thu: true, fri: true, sat: true, sun: true },
                                            },
                                            {
                                                type: "time",
                                                name: "start",
                                                label: "Start",
                                            },
                                            {
                                                type: "time",
                                                name: "end",
                                                label: "End",
                                            },
                                            {
                                                name: "penalty",
                                                label: "Delay",
                                                type: "number",
                                                min: 0,
                                                max: 30,
                                                step: 5,
                                            },
                                        ],
                                    },
                                ],
                                availabilityCheck: (fieldFrom, fieldTo) =>
                                {
                                    return new Promise(async (resolve) =>
                                    {
                                        const responseMessage = await checkIfMemberIsInGroup(
                                            fieldFrom
                                        )

                                        if (
                                            responseMessage &&
                                            responseMessage.success
                                        )
                                        {
                                            resolve({ success: true })
                                        } else
                                        {
                                            resolve({
                                                success: false,
                                                error: responseMessage.message,
                                            })
                                        }
                                    })
                                },
                                submitData: (data) =>
                                {
                                    const days = {
                                        mon: data.days ? data.days.mon : false,
                                        tue: data.days ? data.days.tue : false,
                                        wed: data.days ? data.days.wed : false,
                                        thu: data.days ? data.days.thu : false,
                                        fri: data.days ? data.days.fri : false,
                                        sat: data.days ? data.days.sat : false,
                                        sun: data.days ? data.days.sun : false,
                                    }

                                    return [{
                                        postData: {
                                            days: days,
                                            start: data.start,
                                            end: data.end,
                                            penalty: data.penalty,
                                        },
                                        path: "/callgroup/member/" + id,
                                        identifier: "name",
                                        type: "create",
                                        displayName: data.fromField,
                                    }]
                                },
                            },
                            editModal: {
                                displayName: "Member",
                                uniqueIdentifier: "customName",
                                settingsStages: [
                                    {
                                        title: "Settings",
                                        fields: [
                                            {
                                                type: "daySelector",
                                                name: "days",
                                                label: "Days Active",
                                            },
                                            {
                                                type: "time",
                                                name: "start",
                                                label: "Start",
                                            },
                                            {
                                                type: "time",
                                                name: "end",
                                                label: "End",
                                            },
                                            {
                                                name: "penalty",
                                                label: "Delay",
                                                type: "number",
                                                min: 0,
                                                max: 30,
                                                step: 5,
                                            },
                                        ],
                                    },
                                ],
                                submitData: (data) =>
                                {
                                    const days = {
                                        mon: data.days ? data.days.mon : false,
                                        tue: data.days ? data.days.tue : false,
                                        wed: data.days ? data.days.wed : false,
                                        thu: data.days ? data.days.thu : false,
                                        fri: data.days ? data.days.fri : false,
                                        sat: data.days ? data.days.sat : false,
                                        sun: data.days ? data.days.sun : false,
                                    }
                                    return [{
                                        postData: {
                                            days: days,
                                            start: data.start,
                                            end: data.end,
                                            penalty: data.penalty,
                                        },
                                        path: "/callgroup/member",
                                        identifier: "name",
                                        identifiers: ["name", "groupId"],
                                        type: "edit",
                                        displayName: "displayName",
                                    }]
                                },
                            },
                            deleteModal: {
                                displayName: "Member",
                                uniqueIdentifier: "displayName",
                                submitData: () =>
                                {
                                    return [{
                                        path: "/callgroup/member",
                                        displayName: "name",
                                        type: "delete",
                                    }]
                                },
                            },
                        },
                    ],
                },
            ],
        },
    ]

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        deleteData,
        submitData,
        // changes,
        // resetChanges,
    }
}
