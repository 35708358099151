import React, { useState } from "react"
import { TabbedCardFormPage } from "../../components/Form/TabbedCardFormPage";
import { RecordModal } from "../../components/RecordModal/RecordModal";
import { permissions } from "../../Constants/permissions";
import { useIntegrationsFormPage } from "./useIntegrationsFormPage";


export default function IntegrationsFormPage()
{

    const {
        tabs,
        loading,
        state,
        updateForm,
        updateErrors,
    } = useIntegrationsFormPage()

    return (
        <React.Fragment>
            <TabbedCardFormPage
                tabs={tabs}
                loading={loading}
                state={state}
                updateForm={updateForm}
                updateErrors={updateErrors}
            />
        </React.Fragment>
    )
}