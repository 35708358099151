import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import { ToastContext } from "../../Contexts/ToastContext";
import { useFormReducer } from "../../Hooks/useFormReducer";
import { useRequest } from "../../Hooks/useRequest";
import { sipconvergenceReformatter } from "../../Common/urlHelpers";

export function useIntegrationsFormPage()
{
    const { add } = useContext(ToastContext)
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const { getRequest, postRequest, deleteRequest } = useRequest()

    async function pullData()
    {
        setLoading(true)
        let data = {}

        const tokenResponse = await getRequest('/integration/token/-1/-1')
        const zoho = await getRequest('/zoho/active')

        if (tokenResponse?.records)
        {
            tokenResponse.records.forEach((token) =>
            {
                switch (token.integration)
                {
                    case "zoho":
                        data = { ...data, zohoAccessToken: token.accessToken }
                        break;
                    case "hubspot":
                        data = { ...data, hubspotAccessToken: token.accessToken }
                        break
                }
            })
        }
        if (zoho?.success)
        {
            data = { ...data, active: zoho.active }
        }

        setRecord(data)
        setLoading(false)
    }

    useEffect(() =>
    {
        pullData()
    }, [])

    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record })

    const history = useHistory()

    async function zohoActivate(activate)
    {
        let zohoResponse
        if (activate)
        {
            zohoResponse = await postRequest(null, `/zoho/activation`)
        }
        else
        {
            zohoResponse = await deleteRequest(null, `/zoho/activation`)
        }
        if (zohoResponse.success && zohoResponse.success)
        {
            setRecord({ ...record, active: activate })
            add({
                type: "success",
                text: zohoResponse.message
            })
        }
        else
        {
            add({
                type: "error",
                text: zohoResponse.message
            })
        }
    }

    function getZohoActivationFields(data): IFormField[]
    {
        let fields = []
        if (data && data.zohoAccessToken)
        {
            fields = [
                {
                    type: "info",
                    label: "Login with Zoho",
                    value: "Logged in",
                },
                {
                    type: "info",
                    label: "Status",
                    value: data.active == true ? "Activated" : "Inactive"
                }
            ]
            if (data.active)
            {
                fields = [...fields,
                {
                    type: "button",
                    placeholder: "Deactivate",
                    onClick: () =>
                    {
                        zohoActivate(false)
                    }
                }]
            }
            else
            {
                fields = [...fields,
                {
                    type: "button",
                    placeholder: "Activate",
                    onClick: () =>
                    {
                        zohoActivate(true)
                    }
                }]
            }
        }
        else
        {
            fields = [
                {
                    type: "button",
                    label: "Login with Zoho",
                    placeholder: "Login",
                    onClick: () => (history.push("/zoho")),
                }
            ]
        }

        return fields

    }

    function getHubspotActivationFields(data): IFormField[]
    {
        let fields = []
        if (data && data.hubspotAccessToken)
        {
            fields = [
                {
                    type: "info",
                    value: "VoiceHost installed into Hubspot"
                },
            ]
        }
        else
        {
            fields = [
                {
                    type: "link",
                    value: "https://app-eu1.hubspot.com/oauth/authorize?client_id=91fd027b-1281-4628-953d-a8b14d65f4d6&redirect_uri=https://cp.voicehost.co.uk/hubspotcallback&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.custom.read",
                    // value: "https://app-eu1.hubspot.com/oauth/authorize?client_id=91fd027b-1281-4628-953d-a8b14d65f4d6&redirect_uri=https://cp3.dev.voicehost.co.uk/hubspotcallback&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.custom.read",
                    // value: "https://app-eu1.hubspot.com/oauth/authorize?client_id=91fd027b-1281-4628-953d-a8b14d65f4d6&redirect_uri=http://localhost:3000/hubspotcallback&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.custom.read",
                    text: "Install VoiceHost into my Hubspot",
                    newTab: true
                }
            ]
        }

        return fields

    }

    function getZohoFeaturesFields(data): IFormField[]
    {
        let isActive = "Inactive"
        if (data && data.active && data.active)
        {
            isActive = "Active"
        }

        return [
            {
                type: "info",
                label: "Call Notify",
                value: isActive,
            },
            {
                type: "info",
                label: "Click To Dial",
                value: isActive,
            }
        ]
    }

    const tabs: ITab[] = [
        {
            title: "Zoho",
            icon: "handshake",
            disabled: false,
            sections: [
                {
                    title: "Activation",
                    fields: getZohoActivationFields(record),
                },
                {
                    title: "Features",
                    fields: getZohoFeaturesFields(record)
                }
            ],
        },
        {
            title: "Hubspot",
            icon: "hubspot",
            disabled: false,
            sections: [
                {
                    fields: getHubspotActivationFields(record)
                }
            ],
        },
    ]

    return {
        tabs,
        loading,
        state,
        updateState,
        updateForm,
        updateErrors,
    }
}