import { useContext, useEffect, useState } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"
import useAPIRequests from "../../Hooks/useAPIRequests"
import moment from "moment"
import { hasPermission, permissions } from "../../Constants/permissions"
import { SessionContext } from "../../Contexts/SessionContext"

export function useTimeProfilesFormPage(id: number)
{
    const [loading, setLoading] = useState(true)
    const { state: sessionState } = useContext(SessionContext)
    const [destinationCon, setDestinationCon] = useState<IConditional[]>([])
    const { getRequest } = useRequest()
    const {
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
    } = useFormReducer({ record: {} })
    const { getDestinationPromise, getPromptPromise } = useAPIRequests()

    async function pullData()
    {
        setLoading(true)
        let response = await getRequest(`/timeprofile/${id}`)
        if (response)
        {
            updateState({
                ...response,
                inPromptId:
                    response.inPromptId === null || response.inPromptId === -1
                        ? "none"
                        : response.inPromptId,

                outPromptId:
                    response.outPromptId === null || response.outPromptId === -1
                        ? "none"
                        : response.outPromptId,

                inDestination: response.inDestination ? response.inDestination : "none",
                outDestination: response.outDestination ? response.outDestination : "none",
            })

            let results: IConditional[] = [];
            const destinations = await getDestinationPromise({
                extensionMap: false,
                highPriorityQueue: true,
                excludeObjectRegex: "^TIMEPROFILE\\/" + response.name.replace("*", "\\*") + "$"
            });

            if (destinations && Array.isArray(destinations)) 
            {
                results.push(
                    ...destinations.map((destination: any) => ({
                        text: destination.label,
                        value: destination.value
                    })));
            }

            setDestinationCon(results)
        }
        setLoading(false)
    }

    useEffect(() =>
    {
        pullData()
    }, [id])

    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "General",
                    fields: [
                        {
                            type: "text",
                            label: "Name",
                            name: `description`,
                            required: true,
                            maxLength: 100,
                            helptext: "Allows identification through the control panel."
                        },
                        {
                            type: "info",
                            label: "Time Profile Mode",
                            name: `timeProfileMode`,
                        },
                    ],
                },
                {
                    title: "Active Destination",
                    fields: [
                        {
                            type: "select",
                            label: "Message",
                            name: "inPromptId",
                            loadOptions: () => getPromptPromise(),
                            options: [{ label: "None", value: "none" }],
                            helptext: "Play a Custom Prompt within or outside of the times selected with the slider in Active Time."
                        },
                        {
                            type: "select",
                            label: "Destination",
                            name: "inDestination",
                            options: [{ label: "None", value: "none" }],
                            loadOptions: () =>
                                getDestinationPromise({
                                    extensionMap: false,
                                    highPriorityQueue: true,
                                    excludeObjectRegex: "^TIMEPROFILE\\/" + state.name.replace("*", "\\*") + "$"
                                }),
                            helptext: "Route calls to this destination within or outside of the times selected with the slider in Active Time."
                        },
                    ],
                },
                {
                    title: "Inactive Destination",
                    fields: [
                        {
                            type: "select",
                            label: "Message",
                            name: `outPromptId`,
                            loadOptions: () => getPromptPromise(),
                            options: [{ label: "None", value: "none" }],
                        },
                        {
                            type: "select",
                            label: "Destination",
                            name: `outDestination`,
                            options: [{ label: "None", value: "none" }],
                            loadOptions: () =>
                                getDestinationPromise({
                                    extensionMap: false,
                                    highPriorityQueue: true,
                                    excludeObjectRegex: "^TIMEPROFILE\\/" + state.name.replace("*", "\\*") + "$"
                                })
                        },
                    ],
                },
            ],
        },
        {
            title: "Active Time",
            icon: "clock",
            sections: [
                {
                    fields: [
                        {
                            type: "select",
                            label: "UK Bank Holidays",
                            name: `bankHoliday`,
                            options: [
                                { value: 0, label: "Disabled" },
                                { value: 1, label: "England & Wales" },
                                { value: 2, label: "Scotland" },
                                { value: 3, label: "Northern Ireland" },
                            ],
                            helptext: "If enabled go to Inactive Destination on bank holidays."
                        },
                    ],
                },
                {
                    fullWidth: true,
                    fields: [
                        {
                            type: "formTable",
                            name: "days",
                            searchable: false,
                            fullWidth: true,
                            columns: [
                                {
                                    name: "day",
                                    title: "Day",
                                },
                                {
                                    name: "enabled",
                                    title: "Active",
                                    formField: {
                                        type: "switch",
                                        name: "enabled",
                                    },
                                },
                                {
                                    name: "time",
                                    title: "Time",
                                    formField: {
                                        type: "timeSlider",
                                        name: "time",
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: "Calendars",
            icon: "calendar",
            sections: [
                {
                    fullWidth: true,
                    fields: [
                        {
                            type: "table",
                            customPaginationName: "timeProfileCalendars",
                            restType: "POST",
                            body: {},
                            pullData: pullData,
                            urlString: `/timeprofilecalendar/${id}`,
                            columns:
                                [
                                    {
                                        name: "description",
                                        title: "Description",
                                        link: { root: `/time-profiles/${id}/calendars` },
                                        bold: true
                                    },
                                    {
                                        name: "destination",
                                        title: "Destination",
                                        conditional: destinationCon
                                    },
                                    {
                                        name: "start",
                                        title: "Start"
                                    },
                                    {
                                        name: "end",
                                        title: "End"
                                    },
                                ],
                            addModal: {
                                displayName: "Calendar",
                                allowBulkAdd: false,
                                settingsStages: [
                                    {
                                        title: "Calendar",
                                        fields: [
                                            {
                                                type: "text",
                                                label: "Description",
                                                name: "description",
                                                required: true
                                            },
                                            {
                                                type: "select",
                                                label: "Destination",
                                                name: `destination`,
                                                options: [{ label: "None", value: "" }],
                                                loadOptions: () =>
                                                    getDestinationPromise({
                                                        extensionMap: false,
                                                        highPriorityQueue: true,
                                                        excludeObjectRegex: "^TIMEPROFILE\\/" + state.name.replace("*", "\\*") + "$"
                                                    }),
                                                required: true
                                            },
                                            {
                                                type: "dateTime",
                                                label: "Start",
                                                name: "start",
                                                defaultValue: moment().format("YYYY-MM-DDTHH:mm")
                                            },
                                            {
                                                type: "dateTime",
                                                label: "End",
                                                name: "end",
                                                defaultValue: moment().format("YYYY-MM-DDTHH:mm")
                                            }
                                        ],
                                    }
                                ],
                                submitData: (data) =>
                                {
                                    return [{
                                        postData: {
                                            description: data.description,
                                            destination: data.destination,
                                            start: moment(data.start, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                                            end: moment(data.end, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm"),
                                            timeProfileId: id
                                        },
                                        path: "/timeprofilecalendar",
                                        type: "create",
                                    }]
                                }
                            },
                            deleteModal: {
                                displayName: "Calendar",
                                submitData: (data) =>
                                {
                                    return [{
                                        path: "/timeprofilecalendar",
                                        displayName: "Calendar",
                                        type: "delete",
                                    }]
                                },
                            }
                        }
                    ]
                }
            ]
        }
    ]

    function getDayData(day, days)
    {
        const selectedDay = days.filter(
            (filteredDay) => filteredDay.day === day
        )[0]
        return {
            enabled: selectedDay.enabled,
            start: selectedDay.time[0],
            end: selectedDay.time[1],
        }
    }

    function saveTimeProfile()
    {
        return [
            {
                body: {
                    id: state.id,
                    accountNo: state.accountNo,
                    name: state.name,
                    description: state.description,
                    inPromptId: state.inPromptId !== "none" ? state.inPromptId : 0,
                    outPromptId: state.outPromptId !== "none" ? state.outPromptId : 0,
                    inDestination: state.inDestination !== "none" ? state.inDestination : null,
                    outDestination: state.outDestination !== "none" ? state.outDestination : null,
                    mode: state.mode,
                    bankHoliday: state.bankHoliday,
                    monday: getDayData("Monday", state.days),
                    tuesday: getDayData("Tuesday", state.days),
                    wednesday: getDayData("Wednesday", state.days),
                    thursday: getDayData("Thursday", state.days),
                    friday: getDayData("Friday", state.days),
                    saturday: getDayData("Saturday", state.days),
                    sunday: getDayData("Sunday", state.days),
                },
                path: "/timeprofile/" + id,
            },
        ]
    }

    function deleteTimeProfile()
    {
        return [{
            path: "/timeprofile",
            displayName: "Time Profile",
            type: "delete",
            indexPagePath: "/time-profiles"
        }]
    }

    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        // changes,
        // resetChanges,
        saveTimeProfile,
        deleteTimeProfile,
    }
}
