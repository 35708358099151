import { useContext, useEffect, useState } from "react"
import { useRequest } from "../../Hooks/useRequest"
import { useFormReducer } from "../../Hooks/useFormReducer"
import { ToastContext } from "../../Contexts/ToastContext"
import useAPIRequests from "../../Hooks/useAPIRequests"
import { SessionContext } from "../../Contexts/SessionContext"
import { hasPermission, permissions } from "../../Constants/permissions"
import { regexError } from "../../Constants/regexError"
import { regex } from "../../Constants/regex"

export function useSeatsFormPage(id: number)
{
    const [record, setRecord] = useState({})
    const [loading, setLoading] = useState(true)
    const [newUserModelOpen, setNewUserModelOpen] = useState(false)
    const { getRequest, postRequest, putRequest } = useRequest()
    const { add } = useContext(ToastContext)
    const {
        getDestinationPromise,
        getMusicOnHoldPromise,
        getPromptPromise,
        getMSTeamsPromise,
        getCallerIdPromise,
        getUnrestrictedCountriesPromise,
        getUsersPromise,
        getAllOutboundCLILists
    } = useAPIRequests()
    const {
        state,
        updateState,
        updateForm,
        updateErrors
    } = useFormReducer({ record })

    const { state: sessionState } = useContext(SessionContext)

    function extractOptionsTimes(value)
    {
        const timeRegex = /([0-9]{1,2}:[0-9]{1,2})-([0-9]{1,2}:[0-9]{1,2})/g

        const matches = timeRegex.exec(value)

        if (matches)
        {
            let startTime = matches[1]
            if (startTime.length === 4)
            {
                startTime = "0" + startTime
            }
            let endTime = matches[2]
            if (endTime.length === 4)
            {
                endTime = "0" + endTime
            }
            return [startTime, endTime]
        } else
        {
            return ["00:00", "23:59"]
        }
    }

    async function pullData(firstLoad)
    {
        setLoading(true)
        let data: any = { provisioned: false }

        const seatsResponse = await getRequest(`/seat/${id}`)
        if (seatsResponse)
        {
            if (seatsResponse.success !== false)
            {
                data = {
                    ...data,
                    seat: {
                        ...seatsResponse,
                        options: {
                            ...seatsResponse.options,
                            tps: seatsResponse.options.tps ? seatsResponse.options.tps : "off",
                            ctps: seatsResponse.options.ctps ? seatsResponse.options.ctps : "off",
                        },
                        vmAdvancedPrompt: Number(
                            seatsResponse.vmAdvancedPrompt
                        ),
                        // vmAdvancedPrompt: seatsResponse.vmAdvancedPrompt
                        //     ? seatsResponse.vmAdvancedPrompt
                        //     : 0,
                        // ipaddr: `${seatsResponse.ipAddr ? seatsResponse.ipAddr : "n/a"} Port: ${seatsResponse.port ? seatsResponse.port : "n/a"}${seatsResponse.transport ? ` (${seatsResponse.transport.toUpperCase()})` : ""}`,
                        port: seatsResponse.transport
                            ? `${seatsResponse.port
                                ? seatsResponse.port
                                : "n/a"
                            } (${seatsResponse.currentTransport.toUpperCase()})`
                            : seatsResponse.port,
                        transport: seatsResponse.transport ? seatsResponse.transport : "UDP",
                        status: seatsResponse.online
                            ? `Online (${seatsResponse.server})`
                            : `Offline`,
                        desktopStatus: seatsResponse.desktopOnline ? `Online` : `Offline`,
                        mobileStatus: seatsResponse.mobileOnline ? `Online` : `Offline`,
                        callRestrictions: {
                            weekday: {
                                allow: seatsResponse.options.weekendTimes && seatsResponse.options.weekdayTimes !== "off",
                                times: extractOptionsTimes(
                                    seatsResponse.options.weekdayTimes
                                ),
                            },
                            weekend: {
                                allow: seatsResponse.options.weekendTimes && seatsResponse.options.weekendTimes !== "off",
                                times: extractOptionsTimes(
                                    seatsResponse.options.weekendTimes
                                ),
                            },
                        },
                        enableTeams: !!seatsResponse.options.teamsCLI
                    },
                }

                const voicemailResponse = await getRequest(
                    `/voicemail/${seatsResponse.name}/settings`
                )
                if (voicemailResponse)
                {
                    data = {
                        ...data,
                        voicemail: {
                            ...voicemailResponse,
                            keepOnNetwork: !voicemailResponse.vmDelete,
                        },
                    }
                }
            } else
            {
                data = seatsResponse
            }
        }

        const provisioningResponse = await getRequest(`/seat/${id}/devices`)
        if (provisioningResponse)
        {
            if (
                provisioningResponse.records &&
                provisioningResponse.records.length > 0
            )
            {
                const device = provisioningResponse.records[0]
                data = {
                    ...data,
                    provisioned: true,
                    provisioning: {
                        ...device,
                        locked: device.locked === true ? "Yes" : "No",
                        initialised: device.initialised === true ? "Yes" : "No",
                        ldapPassword:
                            device.ldapPassword === true ? "Yes" : "No",
                    },
                }
            }
        }

        const softphoneResponse = await getRequest(`/seat/${id}/softphone`)

        if (softphoneResponse)
        {
            data = {
                ...data,
                softphone: {
                    enableDesktopCallWaiting: softphoneResponse.desktopSoftphone?.enableCallWaiting,
                    enableMobileCallWaiting: softphoneResponse.mobileSoftphone?.enableCallWaiting,
                    uniqueString: softphoneResponse.mobileSoftphone?.uniqueString,
                    outboundCLILists: softphoneResponse.desktopSoftphone?.outboundCLILists
                }
            }
        }
        const teamsResponse = await getRequest(`/teams/exists/${id}`)
        if (teamsResponse)
        {
            data = {
                ...data,
                teams: {
                    teamsAccountExists: teamsResponse.teamsAccountExists ? teamsResponse.teamsAccountExists : false
                }
            }
        }

        data = {
            ...data,
            enabledcount: 0
        }
        if (firstLoad)
        {
            setRecord(data)
        } else
        {
            updateState(data)
        }
        setLoading(false)
    }

    useEffect(() =>
    {
        pullData(true)
        // eslint-disable-next-line
    }, [id])

    async function getEMSAddresses(): Promise<ISelectOption[]>
    {
        const emsResponse = await postRequest({}, `/emsaddress`)
        if (emsResponse && emsResponse.records)
        {
            return new Promise((resolve) =>
            {
                const formattedEMSAddresses = emsResponse.records.map(
                    (ems) => ({
                        label: `${ems.premises}, ${ems.thoroughfare ? `${ems.thoroughfare}, ` : ""
                            }${ems.locality}, ${ems.postcode}, (${formatNum(ems.telephoneNo)})`,
                        value: ems.telephoneNo,
                    })
                )
                resolve(formattedEMSAddresses)
            })
        }
    }

    function formatNum(num: String)
    {
        if (num.startsWith("44"))
        {
            num = num.replace("44", "0")
        }
        return num
    }

    function getWeekendAndWeekday(seat)
    {
        let weekday
        let weekend

        if (seat.callRestrictions.weekday.allow)
        {
            let startTime = seat.callRestrictions.weekday.times[0]

            let endTime = seat.callRestrictions.weekday.times[1]

            if (startTime !== "00:00" || endTime !== "23:59")
            {
                if (startTime[0] === "0")
                {
                    startTime = startTime.substring(1)
                }
                if (endTime[0] === "0")
                {
                    endTime = endTime.substring(1)
                }
                weekday = `${startTime}-${endTime}`
            } else
            {
                weekday = `on`
            }
        } else
        {
            weekday = "off"
        }

        if (seat.callRestrictions.weekend.allow)
        {
            let startTime = seat.callRestrictions.weekend.times[0]

            let endTime = seat.callRestrictions.weekend.times[1]

            if (startTime !== "00:00" || endTime !== "23:59")
            {
                if (endTime[0] === "0")
                {
                    startTime = startTime.substring(1)
                }
                if (endTime[0] === "0")
                {
                    endTime = endTime.substring(1)
                }

                weekend = `${startTime}-${endTime}`
            } else
            {
                weekend = `on`
            }
        } else
        {
            weekend = "off"
        }

        return { weekday, weekend }
    }

    function deleteData()
    {
        return [{
            path: "/seat",
            type: "delete",
            indexPagePath: "/seats",
            displayName: "Seat"
        }]
    }

    function createNewUserData(data)
    {
        return [{
            path: "/users",
            type: "create",
            postData: {
                name: data.name,
                surname: data.surname,
                username: data.username,
                email: data.email,
                accountNo: state.seat.accountCode,
                enabled: true
            }
        }]
    }

    let newUserSettingsStage: ISettingsStage[] = [
        {
            title: "New User",
            fields: [
                {
                    type: "text",
                    label: "Username",
                    name: "username",
                    required: true,
                    regex: regex.username,
                    regexError: regexError.username
                },
                {
                    type: "text",
                    label: "First Name",
                    name: "name",
                    required: true,
                    regex: regex.usersObjectName,
                    regexError: regexError.usersObjectName
                },
                {
                    type: "text",
                    label: "Last Name",
                    name: "surname",
                    required: true,
                    regex: regex.usersObjectName,
                    regexError: regexError.usersObjectName
                },
                {
                    type: "info",
                    name: "emailWarning",
                    label: "Please Note",
                    defaultValue: "Email entered here is the email that will receive the password for this user."
                },
                {
                    type: "text",
                    label: "Email",
                    name: "email",
                    required: true,
                    regex: regex.email,
                    regexError: regexError.email
                }
            ]
        }
    ]

    function newUserSuccess(response)
    {
        if (response && response.id)
        {
            updateForm({
                target: {
                    name: "seat.userId",
                    value: response.id,
                },
            })
            setNewUserOptions([...newUserOptions, { label: response.name + " " + response.surname, value: response.id }])
        }
    }

    const newUserModal = {
        open: newUserModelOpen,
        setOpen: setNewUserModelOpen,
        settingsStage: newUserSettingsStage,
        defineSubmitData: createNewUserData,
        onSuccess: newUserSuccess
    }

    function regeneratePassword()
    {
        return new Promise(async (resolve) =>
        {
            let response = await getRequest(`/seat/${id}/password/reset`)
            if (response)
            {
                if (response.success)
                {
                    updateForm({
                        target: {
                            name: "seat.secret",
                            value: response.message,
                        },
                    })
                } else
                {
                    add({
                        text: "Something went wrong, please try again.",
                        type: "error",
                    })
                }
            }
            resolve(true)
        })
    }

    function enableTeams()
    {
        if (state && state.teams && state.teams.email && regex.email.test(state.teams.email))
        {
            return new Promise(async (resolve) =>
            {
                let response = await postRequest(
                    {
                        email: state.teams.email,
                        accountNo: state.seat.accountCode
                    },
                    `/teams/admin/invitation`
                )
                if (response && response.success)
                {
                    add({
                        text: "Admin invitation sent",
                        type: "success",
                    })
                }
                else
                {
                    add({
                        text: "Unable to enable teams, please contact support",
                        type: "error",
                    })
                }
                resolve(true)
            })
        }
        else
        {
            add({
                text: "Please enter a valid email to enable teams",
                type: "error",
            })
        }
    }

    function createTeamsAccount()
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest(
                {

                },
                `/teams/${state.seat.accountCode}/create`
            )
            if (response && response.success)
            {
                add({
                    text: "Teams account created",
                    type: "success",
                })
            }
            else
            {
                add({
                    text: "Unable to create teams account, please contact support",
                    type: "error",
                })
            }
            pullData(false)
            resolve(true)
        })
    }

    function reInitialiseProvisionedDevice()
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest(
                {}, `/provisioning/seat/${id}/reinit`
            )
            if (response)
            {
                if (response.success)
                {
                    add({
                        text: "Re-Initialisation Successful",
                        type: "success",
                    })
                } else
                {
                    add({
                        text: "Something went wrong, please try again.",
                        type: "error",
                    })
                }
            }
            resolve(true)
        })
    }

    async function unlockDevice()
    {
        let response = await putRequest({}, `/seat/${id}/unlock`)
        if (response)
        {
            if (response.success)
            {
                updateForm({
                    target: {
                        name: "provisoning.locked",
                        value: false,
                    },
                })
                add({
                    type: "success",
                    text: "Unlocked Successfully",
                })
            } else if (response.message)
            {
                add({
                    type: "error",
                    text: response.message,
                })
            } else
            {
                add({
                    type: "error",
                    text: "An error occured",
                })
            }
        }
    }

    async function regenLDAPPassword()
    {
        let response = await putRequest({}, `/seat/${id}/ldap/regenpassword`)
        if (response)
        {
            if (response.success)
            {
                add({
                    type: "success",
                    text: "Reset Successfully",
                })
            } else if (response.message)
            {
                add({
                    type: "error",
                    text: response.message,
                })
            } else
            {
                add({
                    type: "error",
                    text: "An error occured",
                })
            }
        }
    }

    async function getAccessLists(): Promise<ISelectOption[]>
    {
        return new Promise(async (resolve) =>
        {
            let response = await postRequest({}, `/accesscontrol/-1/-1`)
            if (response && response.records)
            {
                const failoverDestinations = response.records.map(
                    (destination) => ({
                        label: destination.name,
                        value: String(destination.id),
                    })
                )

                resolve(failoverDestinations)
            }
        })
    }

    const [newUserOptions, setNewUserOptions] = useState<ISelectOption[]>(undefined);

    useEffect(() =>
    {
        if (hasPermission([permissions.VHCustomers, permissions.wholesaleCustomers, permissions.wholesaleAdmin, permissions.VHAdmin, permissions.cpUsers, permissions.cpAdmin], sessionState.session.permissions, "create"))
        {
            setNewUserOptions([{
                label: "Create New Softphone User",
                onChange: () => setNewUserModelOpen(true)
            }])
        }
        else
        {
            setNewUserOptions(undefined)
        }
    }, [])


    const tabs: ITab[] = [
        {
            title: "General",
            icon: "toolbox",
            disabled: false,
            sections: [
                {
                    title: "Overview",
                    spanThreeRows: true,
                    fields: [
                        {
                            type: "info",
                            label: "Extension",
                            name: "seat.extension",
                            nullValue: "n/a",
                            halfWidth: true,
                        },
                        {
                            type: "info",
                            label: "Status",
                            name: "seat.status",
                            nullValue: "n/a",
                            halfWidth: true,
                        },
                        {
                            type: "info",
                            label: "Desktop Softphone",
                            name: "seat.desktopStatus",
                            nullValue: "n/a",
                            halfWidth: true,
                        },
                        {
                            type: "info",
                            label: "Mobile Softphone",
                            name: "seat.mobileStatus",
                            nullValue: "n/a",
                            halfWidth: true,
                        },
                        {
                            type: "info",
                            label: "Agent",
                            name: "seat.userAgent",
                            nullValue: "n/a",
                        },
                        {
                            type: "info",
                            label: "IP Address",
                            name: "seat.ipaddr",
                            nullValue: "n/a",
                            helptext:
                                "IP Address - The IP address of the phone is shown here when registered.",
                            halfWidth: true,
                        },
                        {
                            type: "info",
                            label: "Port",
                            name: "seat.port",
                            nullValue: "n/a",
                            halfWidth: true,
                            shortLabel: true,
                        },
                        {
                            type: "info",
                            label: "Network",
                            name: "seat.network"
                        },
                        {
                            conditionallyShow: {
                                checks: [{
                                    field: "provisioned",
                                    value: false,
                                }]
                            },
                            type: "info",
                            name: "seat.name",
                            label: "Username",
                        },
                        {
                            conditionallyShow: {
                                checks: [{
                                    field: "provisioned",
                                    value: false,
                                }]
                            },
                            type: "info",
                            name: "seat.secret",
                            label: "Password",
                        },
                        {
                            conditionallyShow: {
                                checks: [{
                                    field: "provisioned",
                                    value: false,
                                }]
                            },
                            type: "info",
                            label: "Host",
                            name: "seat.host",
                        },
                        {
                            type: "text",
                            name: "seat.nickname",
                            label: "Nickname",
                            helptext:
                                "The nickname allows you to tag the seat so you can identify who is using it or where it is located.",
                            maxLength: 15
                        },
                        {
                            type: "select",
                            name: "seat.ringTime",
                            label: "Ring Time",
                            options: Array.from(Array(12).keys()).map(
                                (number) => ({
                                    value: (number + 1) * 5,
                                    label: `${(number + 1) * 5} Seconds`,
                                })
                            ),
                        },
                        {
                            type: "button",
                            placeholder: "Regenerate Password",
                            onClick: regeneratePassword,
                        },
                    ],
                },
                {
                    title: "Caller Identification",
                    fields: [
                        {
                            conditionallyDisabled: {
                                checks: [{
                                    field: "seat.withholdNumber",
                                    value: true,
                                }]
                            },
                            type: "text",
                            name: "seat.callerIdName",
                            label: "Name",
                            maxLength: 80,
                            helptext: "Caller ID Name sent on outbound calls, please note not all networks support this."
                        },
                        {
                            conditionallyDisabled: {
                                checks: [{
                                    field: "seat.withholdNumber",
                                    value: true,
                                }]
                            },
                            type: "select",
                            name: "seat.callerIdNumber",
                            label: "Number",
                            helptext: "Caller ID Number sent on outbound calls.",
                            loadOptions: getCallerIdPromise,
                        },
                        {
                            type: "switch",
                            name: "seat.withholdNumber",
                            label: "Withhold",
                            helptext: "Sends Anonymous as Caller ID Number and doesn't send the above number on outbound calls. This can be optionally set per call by prefixing 141."
                        },
                    ],
                },
                {
                    title: "Emergency Services",
                    fields: [
                        {
                            type: "select",
                            name: "seat.emergencyServiceCid",
                            label: "Location",
                            heightChange: 60,
                            loadOptions: getEMSAddresses,
                            helptext: "Address attached to telephone number that is passed to the emergency services, each seat must be set with the address at which they are mainly in use."
                        },
                    ],
                },
                {
                    title: "Music On Hold",
                    fields: [
                        {
                            type: "select",
                            name: "seat.musicOnHold",
                            label: "Type",
                            loadOptions: () => getMusicOnHoldPromise(),
                            options: [{ value: "default", label: "Default" }],
                            helptext: "When calls are placed from this seat this is the music played if the call is placed on hold."
                        },
                    ],
                },
            ],
        },
        {
            title: "Voicemail",
            icon: "voicemail",
            sections: [
                {
                    title: "Voicemail",
                    fields: [
                        {
                            type: "select",
                            name: "seat.vmMode",
                            label: "Mode",
                            options: [
                                {
                                    value: 0,
                                    label: "Disabled",
                                },
                                {
                                    value: 1,
                                    label: "Basic",
                                },
                                {
                                    value: 2,
                                    label: "Advanced",
                                },
                            ],
                            helptext: "Basic - standard voicemail greeting to leave a message. Disabled - disables this voicemail box. " +
                                "Advanced - only applicable on DDI calls as a break-out function to optionally route the call elsewhere."
                        },
                    ],
                },
                {
                    title: "Email Settings",
                    conditionallyHide: { checks: [{ field: "seat.vmMode", value: 0 }] },
                    fields: [
                        {
                            type: "text",
                            name: "voicemail.fullname",
                            label: "Name",
                            maxLength: 150,
                            helptext: "Name of email recipient."
                        },
                        {
                            type: "email",
                            name: "voicemail.email",
                            label: "Email",
                            regex: regex.email,
                            regexError: regexError.email,
                            maxLength: 50,
                            helptext: "Email address to send the voicemail to."
                        },
                        {
                            type: "switch",
                            name: "voicemail.keepOnNetwork",
                            label: "Keep On Network",
                            helptext: "When this option is set to no the voicemail message is removed upon email send."
                        },
                    ],
                },
                {
                    title: "Advanced Settings",
                    conditionallyShow: { checks: [{ field: "seat.vmMode", value: 2 }] },
                    fields: [
                        {
                            type: "select",
                            name: "seat.vmAdvancedPromptId",
                            label: "Advanced Prompt",
                            loadOptions: () => getPromptPromise(),
                            options: [
                                { value: 0, label: "Default" },
                            ],
                            defaultValue: 0
                        },
                        {
                            type: "select",
                            name: "seat.vmAdvancedDest",
                            label: "Advanced Destination",
                            defaultToFirst: true,
                            loadOptions: () => getDestinationPromise({
                                highPriorityQueue: false,
                                sipTrunkMap: false,
                                excludeObjectRegex: "^SIP\\/" + state.seat.accountCode + "\\*" + state.seat.extension + "$"
                            }),
                        },
                    ],
                },
                {
                    title: "Remote Access",
                    conditionallyHide: { checks: [{ field: "seat.vmMode", value: 0 }] },
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "Voicemail can be remotely accessed via any telephone by calling the access number" +
                                " below. When prompted enter the mailbox number along with the PIN below.",
                            fullWidth: true,
                        },
                        {
                            type: "info",
                            label: "Access Number",
                            value: "0843 557 4 557",
                        },
                        {
                            type: "info",
                            name: "voicemail.mailbox",
                            label: "Mailbox",
                        },
                        {
                            type: "tel",
                            name: "voicemail.password",
                            label: "PIN",
                        },
                    ],
                },
            ],
        },
        {
            title: "Diverts",
            icon: "directions",
            sections: [
                {
                    title: "Call Diverts",
                    fields: [
                        {
                            type: "text",
                            label: "Divert All",
                            name: "seat.cfwdAll",
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber,
                            helptext: "Divert to this number all direct dial (DDI) calls into the seat."
                        },
                        {
                            type: "text",
                            label: "On Busy",
                            name: "seat.cfwdBusy",
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber,
                            helptext: "Divert to this number when the phone sends busy back to the platform."
                        },
                        {
                            type: "text",
                            label: "On No Answer",
                            name: "seat.cfwdNoAnswer",
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber,
                            helptext: "Divert to this number if not answered by the phone."
                        },
                        {
                            type: "text",
                            label: "On Fallover",
                            name: "seat.cfwdUnavail",
                            regex: regex.telephoneNumber,
                            regexError: regexError.telephoneNumber,
                            helptext: "Divert to this number if the seat is unregistered."
                        },
                    ],
                },
            ],
        },
        {
            title: "Call Recording",
            icon: "microphone-alt",
            sections: [
                {
                    title: "Legal Notice",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "If you monitor and record telephone calls you must comply with the Regulation of" +
                                " Investigatory Powers Act 2000 (RIPA), the Telecommunications " +
                                "(Lawful Business Practice)(Interception of Communications) " +
                                "Regulations 2000 (SI 2000/2699) (the LBP Regulations) and the Data " +
                                "Protection Act 1998.",
                            fullWidth: true,
                        },
                    ],
                },
                {
                    title: "Outbound Call Recording",
                    fields: [
                        {
                            type: "select",
                            options: [
                                { value: "off", label: "None" },
                                { value: "pausable", label: "Enabled - Pausable" },
                                { value: "permanent", label: "Enabled - Non Pausable" },
                            ],
                            name: "seat.options.callRecording",
                            label: "Enable Recording",
                            helptext: "Pausable allows the call to be paused or unpaused."
                        },
                    ],
                },
            ],
        },
        {
            title: "Call Restrictions",
            icon: "minus-circle",
            disabled: !hasPermission([permissions.seatsCallRestrictions, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
            sections: [
                {
                    title: "Legal Notice",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "These restrictions are provided to help reduce the risk of fraud, however do not" +
                                " guarantee to stop any fraudulent attacks originating from equipment connected to the" +
                                " service. It remains your responsibility to secure any equipment connected to the" +
                                " network. By lifting these restrictions you acknowledge the risk and therefore take" +
                                " responsibility for any miss use and charges that may be a consequences of doing so.",
                        },
                    ],
                },
                {
                    title: "Outbound Call Restrictions",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The following call restrictions do not affect emergency calls.",
                        },
                        {
                            type: "switch",
                            label: "Allow UK Geographic",
                            name: "seat.options.allowUKGeo",
                            helptext: "(01, 02 & 03)",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow UK Mobile",
                            name: "seat.options.allowUKMobile",
                            helptext: "(071 to 079)",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow UK Directory",
                            name: "seat.options.allowUK118",
                            helptext: "(118)",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow Other UK Calls",
                            name: "seat.options.allowUKOther",
                            helptext: "(08XX)",
                            halfWidth: true,
                        },
                        {
                            disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                            type: "switch",
                            label: "Allow International Calls",
                            name: "seat.options.allowInternational",
                            halfWidth: true,
                        },
                        {
                            conditionallyShow: {
                                checks: [{
                                    field: "seat.options.allowInternational",
                                    value: true,
                                }]
                            },
                            type: "switch",
                            disabled: !hasPermission([permissions.highRisk, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
                            label: "Allow High Risk Zone",
                            name: "seat.options.allowInternationalHighRisk",
                            helptext:
                                "High Risk Zones are destinations that are considered to have high or extreme risks of telecommunications fraud based on current data",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow UK Premium Calls (09)",
                            name: "seat.options.allowUKPremium09",
                            halfWidth: true,
                        },
                        {
                            type: "switch",
                            label: "Allow UK Premium Calls (070)",
                            name: "seat.options.allowUKPremium070",
                            halfWidth: true,
                        },
                        {
                            type: "select",
                            name: "seat.options.acl",
                            label: "Outbound blacklist",
                            options: [{ value: "0", label: "No Blacklist" }],
                            loadOptions: getAccessLists
                        },
                        hasPermission([permissions.VHAdmin], sessionState.session.permissions, "read") && {
                            type: "number",
                            min: 1,
                            max: 10,
                            name: "seat.options.channelLimit",
                            label: "Seat Channel limit"
                        },
                    ],
                },
                {
                    title: "Outbound Time Restrictions",
                    fields: [
                        {
                            type: "switch",
                            label: "Allow Weekday Calls",
                            name: "seat.callRestrictions.weekday.allow",
                        },
                        {
                            conditionallyShow: {
                                checks: [{
                                    field: "seat.callRestrictions.weekday.allow",
                                    value: true,
                                }]
                            },
                            type: "timeSlider",
                            label: "Between",
                            name: "seat.callRestrictions.weekday.times",
                        },
                        {
                            type: "switch",
                            label: "Allow Weekend Calls",
                            name: "seat.callRestrictions.weekend.allow",
                        },
                        {
                            conditionallyShow: {
                                checks: [{
                                    field: "seat.callRestrictions.weekend.allow",
                                    value: true,
                                }]
                            },
                            type: "timeSlider",
                            label: "Between",
                            name: "seat.callRestrictions.weekend.times",
                        },
                    ],
                },
                {
                    title: "Outbound International Restrictions",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "Require a PIN to be entered when dialling an international destination. \"Allow International Calls\" must be selected to use this feature.",
                            fullWidth: true,
                        },
                        {
                            type: "text",
                            conditionallyDisabled: { checks: [{ field: "seat.options.allowInternational", value: false }] },
                            label: "PIN",
                            name: "seat.options.internationalPIN",
                            regex: regex.internationalPin,
                            regexError: regexError.internationalPin
                        },
                        {
                            type: "paragraph",
                            value:
                                "Restrict the IP location of the calls being made by this seat, to only allow outbound calls to progress from the countries selected below.",
                            fullWidth: true,
                        },
                        {
                            type: "select",
                            name: "seat.options.ipAcl",
                            loadOptions: getUnrestrictedCountriesPromise,
                            multi: true,
                            label: "IP Location Restrictions",
                        }
                    ],
                },
            ],
        },
        {
            title: "TPS/CTPS",
            icon: "user-shield",
            sections: [
                {
                    title: "Telephone Preference Service",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The Telephone Preference Service (TPS) is a list of consumers (including Sole " +
                                "Traders and Partnerships, except in Scotland) who have registered their wish not to " +
                                "receive unsolicited direct marketing calls.",
                        },
                        {
                            type: "paragraph",
                            value:
                                "It is a legal requirement that companies do not make such calls to numbers " +
                                "registered on the TPS. Companies who are reported to the TPS for breach of the above" +
                                " regulation are included in a monthly report sent to the Information Commissioners" +
                                " Office (ICO) the body responsible for enforcement.",
                        },
                        {
                            type: "paragraph",
                            value:
                                "The TPS service checks the TPS register each time a telephone number is dialled." +
                                " If the telephone number appears on the register the caller is notified and depending " +
                                "on the selected option below, can at their discretion, choose to continue their call.",
                        },
                        {
                            type: "paragraph",
                            value:
                                "This is a chargeable service based on the number of TPS checks performed per" +
                                " month. Please check your services tariff for charges.",
                        },
                        {
                            type: "select",
                            label: "TPS Enable",
                            name: "seat.options.tps",
                            options: [
                                { value: "off", label: "Disabled" },
                                {
                                    value: "block",
                                    label: "Enabled (Block Calls)",
                                },
                                {
                                    value: "option",
                                    label: "Enabled (Override Option)",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "Corporate Telephone Preference Service",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The Corporate Telephone Preference Service (CTPS) is a list of organisations " +
                                "(limited companies, public limited companies and Scottish partnerships) who have" +
                                " registered their wish not to receive unsolicited direct marketing calls.",
                        },
                        {
                            type: "paragraph",
                            value:
                                "It is a legal requirement that companies do not make such calls to numbers " +
                                "registered on the CTPS. The original legislation was introduced in May 1999. It has " +
                                "subsequently been updated and now the relevant legislation is the Privacy and " +
                                "Electronic (EC Directive) Regulations 2004 (amended). Companies who are reported to " +
                                "the CTPS for breach of the above regulation are included in a monthly report sent " +
                                "to the Information Commissioners Office (ICO) the body responsible for enforcement.",
                        },
                        {
                            type: "select",
                            label: "Corporate TPS Enable",
                            name: "seat.options.ctps",
                            options: [
                                { value: "off", label: "Disabled" },
                                {
                                    value: "block",
                                    label: "Enabled (Block Calls)",
                                },
                                {
                                    value: "option",
                                    label: "Enabled (Override Option)",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: "Provisioning",
            icon: "wrench",
            sections: [
                {
                    title: "Provisioning Device",
                    fields: [
                        {
                            type: "info",
                            label: "Make",
                            name: "provisioning.make",
                            nullValue: "n/a",
                        },
                        {
                            type: "info",
                            label: "Model",
                            name: "provisioning.model",
                            nullValue: "n/a",
                        },
                        {
                            type: "info",
                            label: "MAC",
                            name: "provisioning.macAddress",
                            nullValue: "n/a",
                        },
                        {
                            type: "info",
                            label: "Last Check-in",
                            name: "provisioning.lastCheckin",
                            nullValue: "n/a",
                        },
                    ],
                },
                {
                    title: "Provisioning Lock",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "Provisioning details are automatically locked once the device has checked" +
                                " in and obtained the Seat configuration details. The details are also automatically" +
                                " locked if a device does not check-in within 15 minutes of being unlocked. To allow " +
                                "a provisioned device to obtain the configuration details after being locked, please" +
                                " click the Unlock button below.",
                        },
                        {
                            type: "info",
                            label: "Locked",
                            name: "provisioning.locked",
                            nullValue: "n/a",
                        },
                        {
                            type: "button",
                            placeholder: "Unlock",
                            onClick: unlockDevice,
                        },
                    ],
                },
                {
                    title: "Provisioning Initialisation",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The first time a device talks to the provisioning service it is assigned a" +
                                " unique set of security details, thereby locking the device to a set of configuration" +
                                " details. If a device is factory reset, it is necessary to re-initialise the security" +
                                " details for the device to provision. To re-initialise the provisioning details, " +
                                "please click the re-initialise button below.",
                        },
                        {
                            type: "info",
                            label: "Initialised",
                            name: "provisioning.initialised",
                            nullValue: "n/a",
                        },
                        {
                            type: "button",
                            placeholder: "Re-Initialise",
                            onClick: reInitialiseProvisionedDevice,
                        },
                    ],
                },
                {
                    title: "Hosted Phone Directory",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The hosted directory settings will be applied to the device via" +
                                " provisioning, please ensure that provisioning is unlocked to allow authentication" +
                                " credentials to be passed to the device.",
                        },
                        {
                            type: "info",
                            label: "Password Set",
                            name: "provisioning.ldapPassword",
                            nullValue: "n/a",
                        },
                        {
                            type: "button",
                            placeholder: "Regenerate Password",
                            onClick: regenLDAPPassword,
                        }
                    ],
                }
            ],
        },
        {
            title: "Teams",
            icon: "users",
            sections: [
                {
                    conditionallyHide: { checks: [{ field: "teams.teamsAccountExists", value: false }] },
                    fields: [
                        {
                            type: "switch",
                            label: "Enable Teams",
                            name: "seat.enableTeams"
                        },
                        {
                            type: "select",
                            label: "Teams Number",
                            name: "seat.options.teamsCLI",
                            loadOptions: () => getMSTeamsPromise(state.seat.name),
                            conditionallyHide: { checks: [{ field: "seat.enableTeams", value: false }] }
                        }
                    ]
                },
                {
                    conditionallyHide: { checks: [{ field: "teams.teamsAccountExists", value: true }] },
                    fields: [
                        {
                            type: "paragraph",
                            value: "To enable Teams on this account, please enter the Office 365 admin account email and get them to register and follow the instructions below. You will not be able to enable Teams again until that happens."
                        },
                        {
                            type: "paragraph",
                            value: "Once the admin accepts the invitation they will need to navigate to the call2teams portal. Now click into your portal on the right hand side, "
                                + "then go into Services section. After that click on the Teams icon and lastly click the 'Enable Sync' button. "
                        },
                        {
                            type: "paragraph",
                            value: "Users will now be able to enable Teams for their seat or SIP trunk. Every time a user enables Teams you will have to sync their Office 365 account via the Teams portal. "
                                + "To do this log into the portal, go into the same services section but this time click on the trunk icon. Now find the appropriate trunk, "
                                + "finally select the user you want using the trunk."
                        },
                        {
                            label: "Admin Email",
                            type: "text",
                            name: "teams.email",
                            regex: regex.email,
                            regexError: regexError.email,
                            maxLength: 50
                        },
                        {
                            type: "button",
                            placeholder: "Send Admin Invite",
                            onClick: enableTeams
                        }
                    ]
                },
                {
                    conditionallyHide: { checks: [{ field: "teams.teamsAccountExists", value: true }] },
                    fields: [
                        {
                            type: "paragraph",
                            value: "By pressing this button you override the need to provide an admin email address for teams."
                                + " Please Ensure the person who manages your office 365 account has access before pressing this."
                        },
                        {
                            type: "button",
                            placeholder: "Create Teams Account",
                            onClick: createTeamsAccount
                        }
                    ]
                }
            ]
        },
        {
            title: "Advanced",
            icon: "tools",
            sections: [
                {
                    title: "Video Calling",
                    fields: [
                        {
                            type: "switch",
                            label: "Enable Video",
                            name: "seat.enableVideo",
                        },
                        {
                            type: "select",
                            conditionallyShow: {
                                checks: [{
                                    field: "seat.enableVideo",
                                    value: true,
                                }]
                            },
                            label: "Video Codec",
                            name: "seat.videoCodec",
                            defaultValue: "h264",
                            options: [
                                { label: "vp9", value: "vp9" },
                                { label: "vp8", value: "vp8" },
                                { label: "h264", value: "h264" },
                            ],
                        },
                    ],
                },
                {
                    title: "Network Transport",
                    spanTwoRows: true,
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "The default transport method for Seats is UDP and is supported by all handsets " +
                                "and softphones. Some phones such as the Snom, Cisco and Yealink also support secure" +
                                " communication using TLS (Transport Layer Security) and SRTP (Secure Real-time " +
                                "Transport).",
                        },
                        {
                            type: "paragraph",
                            value:
                                "By selecting the TLS option below, communication between the handset and our" +
                                " network is encrypted. Any phone connected to our provisioning server will require" +
                                " a reboot and will automatically be configured to encrypt communications.",
                        },
                        {
                            type: "paragraph",
                            value:
                                "Encryption is only possible to and from our own network, calls that pass to other" +
                                " networks or other Seats that do not have TLS enabled will not be secure.",
                        },
                        {
                            type: "paragraph",
                            value:
                                "The use of TLS is a chargeable service, please check your services tariff " +
                                "for charges.",
                        },
                        {
                            type: "select",
                            label: "Transport",
                            options: [
                                { label: "UDP", value: "udp" },
                                { label: "TCP", value: "tcp" },
                                { label: "TLS", value: "tls" },
                            ],
                            name: "seat.transport",
                        },
                    ],
                },
                {
                    title: "HD Calling",
                    fields: [
                        {
                            type: "paragraph",
                            value:
                                "By default HD calling is disabled due to incompatibility with some handsets. " +
                                "To enable HD calling for a seat, please enable it below and reboot your device.",
                        },
                        {
                            type: "switch",
                            label: "HD",
                            name: "seat.hdCodec",
                        },
                    ],
                },
            ],
        },
        {
            title: "Desktop Softphone",
            icon: "Desktop",
            disabled: !hasPermission([permissions.softphone, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
            sections: [
                {
                    title: "Provisioning",
                    fields: [
                        {
                            type: "info",
                            value: "Please download the Desktop Softphone from the Microsoft App Store using the link below. "
                        },
                        {
                            type: "images",
                            images: [
                                {
                                    style: { height: "50px" },
                                    src: require("../../Assets/microsoft-store-badge.svg"),
                                    link:
                                        "https://apps.microsoft.com/store/detail/desktop-softphone/9NT2H0K3TJ3V?hl=en-us&gl=us",
                                    openLinkInNewWindow: true
                                },
                            ],
                        },
                        {
                            type: "info",
                            value: "To use the Desktop Softphone with this seat, a user must be paired to it below. "
                                + "On opening the application, enter the LoginDot details for the selected user and then this seat will be used."
                        },
                        {
                            type: "select",
                            label: "Seat User",
                            loadOptions: getUsersPromise,
                            options: newUserOptions,
                            updateOn: newUserOptions,
                            name: "seat.userId"
                        },
                    ],
                },
                {
                    title: "Softphone Options",
                    fields: [
                        {
                            type: "switch",
                            label: "Enable Call Waiting",
                            name: "softphone.enableDesktopCallWaiting"
                        },
                        {
                            type: "select",
                            multi: true,
                            label: "Outbound Caller ID Lists",
                            name: "softphone.outboundCLILists",
                            loadOptions: getAllOutboundCLILists,
                            permissions: [permissions.outboundCLILists, permissions.VHadmin, permissions.admin]
                        }
                    ]
                },
            ],
        },
        {
            title: "Mobile Softphone",
            icon: "mobile-alt",
            disabled: !hasPermission([permissions.softphone, permissions.admin, permissions.VHAdmin], sessionState.session.permissions, "read"),
            sections: [
                {
                    title: "Provisioning",
                    fields: [
                        {
                            type: "info",
                            value: "Please download the Softphone at the below links, then use the application to scan the below QR code"
                        },
                        {
                            type: "qrCode",
                            nullValue:
                                "Please save the seat so a QR code can be generated",
                            name: "softphone.uniqueString",
                        },
                        {
                            type: "images",
                            images: [
                                {
                                    src: require("../../Assets/google-play-badge.png"),
                                    link:
                                        "https://play.google.com/store/apps/details?id=co.uk.voicehost.softphone.android",
                                },
                                {
                                    src: require("../../Assets/app-store-badge.png"),
                                    link:
                                        "https://itunes.apple.com/us/app/softphone/id1195110344",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "Options",
                    fields: [
                        {
                            type: "switch",
                            label: "Enable Call Waiting",
                            name: "softphone.enableMobileCallWaiting"
                        }
                    ]
                }
            ],
        }
    ]

    function submitData()
    {
        let vmEnable = null
        let vmAdvanced = null
        let vmAdvancedDest = null
        let vmAdvancedPromptId = -1

        if (state.seat.vmMode === 1)
        {
            vmEnable = true
            vmAdvanced = false
            vmAdvancedDest = null
            vmAdvancedPromptId = -1
        } else if (state.seat.vmMode === 2)
        {
            vmEnable = false
            vmAdvanced = true
            vmAdvancedDest = state.seat.vmAdvancedDest
            vmAdvancedPromptId = state.seat.vmAdvancedPromptId
        } else
        {
            vmEnable = false
            vmAdvanced = false
            vmAdvancedDest = null
            vmAdvancedPromptId = -1
        }

        const { weekend, weekday } = getWeekendAndWeekday(state.seat)
        return [
            {
                body: {
                    enableDesktopCallWaiting: state.softphone.enableDesktopCallWaiting,
                    enableMobileCallWaiting: state.softphone.enableMobileCallWaiting,
                    outboundCLILists: state.softphone.outboundCLILists
                },
                path: `/seat/${id}/softphone`,
                formPrefix: "softphone",
            },
            {
                body: {
                    cfwdAll: state.seat.cfwdAll,
                    cfwdBusy: state.seat.cfwdBusy,
                    cfwdUnavail: state.seat.cfwdUnavail,
                    cfwdNoAnswer: state.seat.cfwdNoAnswer,

                    vmMode: state.seat.vmMode,
                    fullname: state.seat.fullname,
                    email: state.seat.email,

                    vmAdvancedDest: vmAdvancedDest,
                    vmAdvancedPromptId: vmAdvancedPromptId,

                    host: state.seat.host,
                    insecure: state.seat.insecure,
                    mailbox: state.seat.mailbox,
                    deny: state.seat.deny,
                    permit: state.seat.permit,
                    secret: state.seat.secret,
                    defaultUser: state.seat.defaultUser,
                    musicOnHold: state.seat.musicOnHold,
                    emergencyServiceCid: state.seat.emergencyServiceCid,

                    callerId: state.seat.callerIdName + " <" + state.seat.callerIdNumber + ">",
                    vmEnable: vmEnable,
                    withholdNumber: state.seat.withholdNumber ? 1 : 0,
                    vhNickname: state.seat.nickname,
                    // email: state.voicemail.email,
                    vmAdvanced: vmAdvanced,
                    // vmAdvancedDest: state.seat.vmAdvancedDest,
                    // vmAdvancedPrompt: state.seat.vmAdvancedPrompt,
                    ringTime: state.seat.ringTime,
                    sendDDI: state.seat.sendDDI,
                    trunkNoPlus: state.seat.trunkNoPlus,
                    trunkFailOver: state.seat.trunkFailOver,
                    // transport: state.seat.transport,

                    enableVideo: state.seat.enableVideo,
                    videoCodec: state.seat.enableVideo ? state.seat.videoCodec ? state.seat.videoCodec : "" : null,
                    transport: state.seat.transport,
                    hdCodec: state.seat.hdCodec,
                    twinSeat: state.softphone.enabled === "twinned",
                    enableTeams: state.seat.enableTeams,
                    userId: state.seat.userId,
                    options: {
                        ...state.seat.options,
                        weekdayTimes: weekday,
                        weekendTimes: weekend,
                        teamsCLI: state.seat.enableTeams ? state.seat.options.teamsCLI : null
                    },
                },
                path: "/seat/" + id,
                formPrefix: "seat",
            },
            {
                body: {
                    vmDelete: !state.voicemail.keepOnNetwork,
                    fullname: state.voicemail.fullname
                        ? state.voicemail.fullname
                        : null,
                    email: state.voicemail.email
                        ? state.voicemail.email
                        : null,
                    password: state.voicemail.password
                        ? state.voicemail.password
                        : null,
                },
                path: `/voicemail/${state.seat.name}/settings`,
                formPrefix: "voicemail",
            },
        ]
    }


    return {
        loading,
        tabs,
        state,
        updateState,
        updateForm,
        updateErrors,
        submitData,
        deleteData,
        pullData,
        newUserModelOpen,
        setNewUserModelOpen,
        newUserModal
    }
}
