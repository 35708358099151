import
{
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useReducer,
} from "react"
import { useRequest } from "../../Hooks/useRequest"
import { ToastContext } from "../../Contexts/ToastContext"

export function useEditableTable(
    restType,
    urlString,
    body,
    noPagination,
    ref,
    onSubmit,
    customPaginationName
)
{
    const { getRequest, postRequest, putRequest } = useRequest()
    const { add } = useContext(ToastContext)

    const actions = {
        titleChange: `TITLE_CHANGED`,
        recordsChanged: "RECORD_CHANGED",
        offsetChanged: `OFFSET_CHANGED`,
        limitChanged: `LIMIT_CHANGED`,
        totalRecordsChanged: `TOTAL_RECORDS_CHANGED`,
        newModalOpenStatusChanged: `NEW_OPEN_MODAL_STATUS_CHANGED`,
        editModalOpenStatusChanged: `EDIT_OPEN_MODAL_STATUS_CHANGED`,
        deleteModalOpenStatusChanged: `DELETE_OPEN_MODAL_STATUS_CHANGED`,
        fileUploadModalOpenStatusChanged: `FILE_UPLOAD_OPEN_MODAL_STATUS_CHANGED`,
        sortByChanged: `SORT_BY_CHANGED`,
        columnsChanged: `COLUMNS_CHANGED`,
        filtersChanged: `FILTERS_CHANGED`,
        toastChanged: `TOAST_CHANGED`,
        loadingChanged: `LOADING_CHANGED`,
        selectedRecordChanged: `SELECTED_RECORDS_CHANGED`,
        restTypeChanged: `REST_TYPE_CHANGED`,
        failedRecordsChanged: `FAILED_RECORDS_CHANGED`,
        deletedRecordsChanged: `DELETED_RECORDS_CHANGED`,
        onSearchChanged: `ON_SEARCH_CHANGED`,
        updateRow: `UPDATE_ROW`,
        updateErrors: `UPDATE_ERRORS`,
        clearErrors: `CLEAR_ERRORS`,
        setReferenceRecord: `SET_REFERENCE_RECORD`,
    }

    function confirmChanges()
    {
        if (
            state &&
            state.records &&
            state.records.filter((record) => record.isChanged).length > 0
        )
        {
            return window.confirm(
                "There are unsaved changes in this table. Changing page will discard these changes. Click 'OK' if you wish to continue."
            )
        } else
        {
            return true
        }
    }

    function indexReducer(state, action)
    {
        const { payload, type } = action
        switch (type)
        {
            case actions.titleChange:
                return { ...state, title: payload }
            case actions.recordsChanged:
                return { ...state, records: payload }
            case actions.offsetChanged:
                if (confirmChanges())
                {
                    return { ...state, offset: payload }
                } else
                {
                    return state
                }
            case actions.limitChanged:
                if (confirmChanges())
                {
                    return { ...state, limit: payload }
                } else
                {
                    return state
                }
            case actions.totalRecordsChanged:
                return { ...state, totalRecords: payload }
            case actions.newModalOpenStatusChanged:
                return { ...state, newModalOpenStatus: payload }
            case actions.editModalOpenStatusChanged:
                return { ...state, editModalOpenStatus: payload }
            case actions.deleteModalOpenStatusChanged:
                return { ...state, deleteModalOpenStatus: payload }
            case actions.fileUploadModalOpenStatusChanged:
                return { ...state, fileUploadModalOpenStatus: payload }
            case actions.sortByChanged:
                return { ...state, sortBy: payload }
            case actions.columnsChanged:
                return { ...state, columns: payload }
            case actions.filtersChanged:
                return { ...state, filters: payload }
            case actions.toastChanged:
                return { ...state, toast: payload }
            case actions.loadingChanged:
                return { ...state, loading: payload }
            case actions.selectedRecordChanged:
                return { ...state, selectedRecords: payload }
            case actions.restTypeChanged:
                return { ...state, restType: payload }
            case actions.failedRecordsChanged:
                return { ...state, failedRecords: payload }
            case actions.deletedRecordsChanged:
                return { ...state, deletedRecords: payload }
            case actions.onSearchChanged:
                return { ...state, onSearch: payload }
            case actions.setReferenceRecord:
                return { ...state, referenceRecord: payload }
            case actions.updateRow:
                const records = state.records
                const { name, row, value } = payload
                records[row][name] = value

                delete records[row].isChanged

                // lawd forgive me
                records[row].isChanged =
                    JSON.stringify(records[row]) !==
                    JSON.stringify(JSON.parse(state.referenceRecord)[row])

                return { ...state, records }

            case actions.updateErrors:
                let tempErrors = []
                if (state && state.errors)
                {
                    tempErrors = state.errors.filter(
                        (error) =>
                            error.name !== payload.name &&
                            error.row === payload.row
                    )
                }
                if (payload.value)
                {
                    tempErrors = [
                        ...tempErrors,
                        {
                            name: payload.name,
                            value: payload.value,
                            row: payload.row,
                        },
                    ]
                }
                return {
                    ...state,
                    errors: tempErrors,
                }
            case actions.clearErrors:
                const tempState = state
                delete tempState.errors

                return tempState
            default:
                return state
        }
    }

    function getInitialLimit()
    {
        if (customPaginationName)
        {
            if (localStorage.getItem(`pagination/${customPaginationName}`))
            {
                return Number(localStorage.getItem(`pagination/${customPaginationName}`))
            }
            else
            {
                return 5
            }
        }
        else if (localStorage.getItem(`pagination${window.location.pathname}`))
        {
            return Number(localStorage.getItem(`pagination${window.location.pathname}`))
        }
        else
        {
            return 15
        }
    }

    const initialState = {
        title: "",
        records: [],
        offset: 0,
        limit: getInitialLimit(),
        totalRecords: 0,
        newModalOpenStatusChanged: false,
        editModalOpenStatusChanged: false,
        deleteModalOpenStatusChanged: false,
        fileUploadModalOpenStatusChanged: false,
        sortBy: {
            column: "extension",
            direction: "asc",
        },
        columns: [],
        filters: [],
        request: useRequest(),
        toast: useContext(ToastContext),
        selectedRecords: [],
        restType: restType ? restType : "GET",
        referenceRecord: "",
    }

    const [state, dispatch] = useReducer(indexReducer, initialState)

    const updateRow = useCallback(({ target: { value, name, type, row } }) =>
    {
        dispatch({
            type: actions.updateRow,
            payload: { value, name, type, row },
        })
    }, [])

    const updateErrors = useCallback(
        ({ target: { value, name, type, row } }) =>
        {
            dispatch({
                type: actions.updateErrors,
                payload: { value, name, type, row },
            })
        },
        []
    )

    async function pullData()
    {
        dispatch({ type: actions.loadingChanged, payload: true })
        let response

        const url = noPagination
            ? `${urlString}`
            : `${urlString}/${state.offset}/${state.limit}`

        if (restType === "GET")
        {
            response = await getRequest(url)
        }
        if (restType === "POST")
        {
            if (state.sortBy)
            {
                body = {
                    ...body,
                    sortBy: state.sortBy.column,
                    direction: state.sortBy.direction,
                }
            }
            if (state.onSearch)
            {
                body = { ...body, search: state.onSearch }
            }
            response = await postRequest(body, url)
        }

        if (response && response.records)
        {
            dispatch({
                type: actions.setReferenceRecord,
                payload: JSON.stringify(response.records),
            })
            dispatch({
                type: actions.recordsChanged,
                payload: response.records,
            })
            dispatch({
                type: actions.totalRecordsChanged,
                payload: response.totalRecords,
            })
        } else
        {
            dispatch({
                type: actions.toastChanged,
                payload: {
                    text: "The request failed.",
                    type: "error",
                },
            })
        }

        dispatch({ type: actions.loadingChanged, payload: false })
    }

    function getError(row, name)
    {
        if (state && state.errors)
        {
            const errors = state.errors.filter(error => error.row === row && error.name === name)
            if (errors && errors.length)
            {
                return errors[0].value
            }
        }
    }

    useEffect(() =>
    {
        pullData()
    }, [state.limit, state.offset, state.sortBy, state.onSearch])

    useImperativeHandle(ref, () => ({
        handleSubmit()
        {
            return new Promise(async (resolve) =>
            {
                let success = false

                if (state.errors && state.errors.length)
                {
                    resolve({ success: false, message: "The table didn't update, please fix any errors to continue" })
                    add({
                        text: "The table didn't update, please fix any errors to continue",
                        type: "error",
                    })
                }
                else
                {
                    const recordsToSave = state.records.filter(
                        (record) => record.isChanged
                    )
                    const successes = []
                    const failures = []

                    for (let i = 0; i < recordsToSave.length; i++)
                    {
                        const record = recordsToSave[i]
                        const body = onSubmit.getData(record)

                        const uniqueIdentifier = onSubmit.uniqueIdentifier
                            ? onSubmit.uniqueIdentifier
                            : body.id
                        let response = await putRequest(
                            body,
                            onSubmit.path + `/` + uniqueIdentifier
                        )

                        if (response)
                        {
                            if (response.success)
                            {
                                successes.push({
                                    id:
                                        record[
                                        onSubmit.displayName
                                            ? onSubmit.displayName
                                            : "id"
                                        ],
                                    message: "edited successfully",
                                })
                            } else
                            {
                                if (response.validation)
                                {
                                    for (let key in response.validation)
                                    {
                                        if (
                                            response.validation.hasOwnProperty(key)
                                        )
                                        {
                                            let row
                                            state.records.map((record, index) =>
                                            {
                                                if (
                                                    record[
                                                    onSubmit.uniqueIdentifier
                                                        ? onSubmit.uniqueIdentifier
                                                        : "id"
                                                    ] === uniqueIdentifier
                                                )
                                                {
                                                    row = index
                                                }
                                            })
                                            updateErrors({
                                                target: {
                                                    row: row,
                                                    name: key,
                                                    value: response.validation[key],
                                                },
                                            })
                                            failures.push({
                                                id:
                                                    record[
                                                    onSubmit.displayName
                                                        ? onSubmit.displayName
                                                        : "id"
                                                    ],
                                                message: response.validation[key],
                                            })
                                        }
                                    }
                                } else
                                {
                                    failures.push({
                                        id:
                                            record[
                                            onSubmit.displayName
                                                ? onSubmit.displayName
                                                : "id"
                                            ],
                                        message: response.message,
                                    })
                                }
                            }
                        } else
                        {
                            failures.push({
                                id:
                                    record[
                                    onSubmit.displayName
                                        ? onSubmit.displayName
                                        : "id"
                                    ],
                                message: "Something went wrong please try again",
                            })
                        }
                    }
                    if (failures.length > 0)
                    {
                        add({
                            text: `One or more entries in the table could not be updated: ${failures[0].message}`,
                            type: "error",
                        })
                    } else
                    {
                        success = true
                        pullData()
                    }

                    resolve({ success, successes, failures })
                }
            })
        },
        hasChanges:
            state &&
            state.records &&
            state.records.filter((record) => record.isChanged).length > 0,
    }))

    return {
        state,
        dispatch,
        actions,
        updateRow,
        updateErrors,
        getError
    }
}
